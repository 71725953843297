import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { SHOP_ENABLED } from '../../../graphql/shop/shopEnabled';
import offlinePicture from '../../../assets/offline.jpg';
import './style.css';

const ShopEnabledWrapper: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { data, loading } = useQuery(SHOP_ENABLED);
  const legacyAxessShopEnabled = data?.shopStatus?.legacyAxessShopEnabled;
  const legacyAxessShopDisabledMessage =
    data?.shopStatus?.legacyAxessShopDisabledMessage;
  const { t } = useTranslation();

  if (loading) return null;
  return (
    <>
      {legacyAxessShopEnabled !== false ? (
        <>{children}</>
      ) : (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
          <img
            src={offlinePicture}
            alt={'offline'}
            className={'offlineImage'}
          />
          <div className={'offlineInfo'}>
            <h2>
              <b>{t('commons.ticketShopOffline')}</b>
            </h2>
            <h4 style={{ whiteSpace: 'pre-line' }}>
              {legacyAxessShopDisabledMessage &&
              typeof legacyAxessShopDisabledMessage === 'string' ? (
                legacyAxessShopDisabledMessage
              ) : (
                <>
                  {t('commons.offlineInfo')}
                  <a href={'mailto:feedback@laax.com'}>feedback@laax.com</a>.
                </>
              )}
            </h4>
          </div>
        </div>
      )}
    </>
  );
};

export default ShopEnabledWrapper;
