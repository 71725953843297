import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../atoms/Modal';
import Button from '../../atoms/buttons/SubmitButton/SubmitButton';
import MainOptionButton from '../../atoms/buttons/MainOptionButton/MainOptionButton';
import { AppContext } from '../../../AppContext';
import { useQuery } from '@apollo/client';
import { FETCH_PARTY_MEMBERS } from '../../../graphql/user/user';
import Subheader from '../../atoms/Subheader/Subheader';
import SmallText from '../../atoms/SmallText/SmallText';
import ticketIcon from '../../../icons/ticket2.svg';
import keycardImage from '../../../assets/keycard-laax@4x.png';
import './style.css';

const ConfirmKeyCardsModal: FC<any> = ({
  isOpen,
  setIsOpen,
  onSubmit,
  isSubmitBtnDisabled,
}) => {
  const { t } = useTranslation();
  const { chosenPartyMembersOnTicketState } = useContext(AppContext);

  const [chosenPartyMembersOnTicket] = chosenPartyMembersOnTicketState;
  const fetchedPartyMembers = useQuery(FETCH_PARTY_MEMBERS, {
    fetchPolicy: 'network-only',
  });
  const partMembers = fetchedPartyMembers?.data?.guest?.partyMembers || [];

  return (
    <Modal
      isOpen={isOpen}
      height={220 + chosenPartyMembersOnTicket.length * 100 + 'px'}
    >
      <div className={'confirmKeyCardContainer'}>
        <div style={{ padding: '0 12px' }}>
          <div style={{ paddingRight: '24px' }}>
            <Subheader text={t('checkoutView.confirmTicketDetails')} isBlack />
          </div>
          <SmallText text={t('checkoutView.loadedKeycard')} isNoUppercase />
        </div>
        <div className='exitButton'>
          <MainOptionButton onClick={(): void => setIsOpen(false)}>
            x
          </MainOptionButton>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '10px',
          }}
        >
          <div style={{ paddingBottom: '20px' }}>
            {chosenPartyMembersOnTicket?.map((id: string) => {
              const member = partMembers.find((pM: any) => pM.id === id);
              return (
                <div
                  key={id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    padding: '6px',
                    border: '1px solid #f0f0f0',
                    borderRadius: '10px',
                    marginBottom: '10px',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {member?.keycard ? (
                      <img width={'90px'} src={keycardImage} alt={'keycard'} />
                    ) : (
                      <div
                        style={{
                          width: '90px',
                          height: '60px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <img
                          width={'40px'}
                          src={ticketIcon}
                          alt={'closeIcon'}
                        />
                      </div>
                    )}
                    <div style={{ paddingRight: '20px', paddingLeft: '4px' }}>
                      <div
                        style={{
                          fontSize: '14px',
                          fontWeight: 'bold',
                          paddingBottom: '2px',
                        }}
                      >
                        {member?.firstName + ' ' + member?.lastName}
                      </div>
                      <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        {member?.keycard && (
                          <SmallText
                            text={t('checkoutView.keyCardNumber') + ' '}
                            isNoUppercase
                          />
                        )}
                        {member?.keycard
                          ? member?.keycard?.displayNumber
                          : t('checkoutView.newKeyCard')}
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => setIsOpen(false)}
                    style={{ flex: 1, textAlign: 'end', paddingRight: '10px' }}
                  >
                    <SmallText
                      text={t('commons.change')}
                      isLink
                      isNoUppercase
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className={'center'}>
            <Button
              className='submit-button'
              onClick={onSubmit}
              disabled={isSubmitBtnDisabled}
            >
              {t('commons.confirm')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmKeyCardsModal;
