import { sanityDataset, sanityProjectId } from '../client';

const getImageLink = (image: string) => {
  const imageArray = image ? image.split('-') : [];
  const imageName = imageArray[1] || '';
  const imageSize = imageArray[2] || '';
  const imageType = imageArray[3] || '';
  const correctLink = imageName && imageSize && imageType;
  return correctLink
    ? `https://cdn.sanity.io/images/${sanityProjectId}/${sanityDataset}/${imageName}-${imageSize}.${imageType}?h=400`
    : undefined;
};

export default getImageLink;
