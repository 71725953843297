import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Subheader from '../../atoms/Subheader/Subheader';
import usersRed from '../../../icons/users-red.svg';
import SmallText from '../SmallText/SmallText';
import addPerson from '../../../icons/user-plus.svg';
import './GroupMember.css';

import AddMemberModal from '../../molecules/AddMemberModal/AddMemberModal';
import ErrorToast from '../ErrorToast/ErrorToast';

import { useMutation, useQuery } from '@apollo/client';
import {
  FETCH_GROUP_MEMBERS,
  REMOVE_PARTY_MEMBER,
} from '../../../graphql/user/user';
import { toast } from 'react-toastify';
import MemberList from './MemberList';
import EditMemberModal from '../../molecules/EditMemberModal';
import RemoveMemberModal from '../../molecules/RemoveMemberModal/RemoveMemberModal';
import { KeycardType } from '../../../constans/keycardType';

const GroupMembers: FC = () => {
  const { t } = useTranslation();
  const [toggleAddPersonModal, setToggleAddPersonModal] = useState(false);
  const [toggleEditMemberModal, setToggleEditMemberModal] = useState(false);
  const [toggleRemoveMemberModal, setToggleRemoveMemberModal] = useState(false);

  const [editMember, setEditMember] = useState<any>({});

  const [memberToRemoveID, setMemberToRemoveID] = useState('');

  const fetchedGroupMembers = useQuery(FETCH_GROUP_MEMBERS);
  const [removePartyMember] = useMutation(REMOVE_PARTY_MEMBER);
  const submitSuccessfullRemove = (): void => {
    toast(t('toasts.removeSuccess'), { type: 'success' });
    fetchedGroupMembers.refetch();
  };

  const { error: fetchError } = useQuery(FETCH_GROUP_MEMBERS);

  const statusCode =
    fetchError?.message.indexOf('400') !== -1 ? 400 : undefined;
  const submitError = (): void => {
    toast(t('toasts.serverError'), { type: 'error' });
  };

  const onRemoveClick = () => {
    removePartyMember({
      variables: { partyMemberID: { id: memberToRemoveID } },
    }).then((res) =>
      res?.data?.removePartyMember ? submitSuccessfullRemove() : submitError()
    );
  };

  useEffect(() => {
    fetchedGroupMembers.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEditClick = (partyMember: any) => {
    setEditMember({
      id: partyMember?.id,
      firstName: partyMember.firstName,
      lastName: partyMember?.lastName || null,
      dayBirth: new Date(partyMember.dateOfBirth).getDate(),
      monthBirth: new Date(partyMember.dateOfBirth).getMonth(),
      yearBirth: new Date(partyMember.dateOfBirth).getFullYear(),
      keyCardType: partyMember?.keycard?.type || KeycardType.NO_KEYCARD,
      keyCardDisplayNumber: partyMember?.keycard?.displayNumber ?? '',
      zip: partyMember?.keycard?.zip ?? '',
      swissPassApproval: Boolean(partyMember?.keycard?.zip),
    });
    editMember && setToggleEditMemberModal(true);
  };
  const memberListProps = {
    members: fetchedGroupMembers?.data?.guest.partyMembers || [],
    onRemoveClick,
    onEditClick,
    setToggleRemoveMemberModal,
    setMemberToRemoveID,
  };
  return (
    <>
      {fetchError && <ErrorToast statusCode={statusCode} />}
      <Subheader
        icon={usersRed}
        text={String(t('user.groupMembers')).toUpperCase()}
        isBlack
      />
      <MemberList {...memberListProps} />
      <div
        className='addPersonButton'
        onClick={() => setToggleAddPersonModal(true)}
      >
        <img src={addPerson} alt='add person icon' className='addPerson' />
        <SmallText isLink isNoUppercase text={t('checkoutView.addPerson')} />
      </div>

      <AddMemberModal
        isOpen={toggleAddPersonModal}
        refresh={fetchedGroupMembers.refetch}
        isUserPanel={true}
        setToggleAddMemberModal={setToggleAddPersonModal}
      />

      <EditMemberModal
        isOpen={toggleEditMemberModal}
        refresh={fetchedGroupMembers.refetch}
        setToggleEditMemberModal={setToggleEditMemberModal}
        setEditMember={setEditMember}
        initialValues={editMember}
      />

      <RemoveMemberModal
        isOpen={toggleRemoveMemberModal}
        setToggleRemoveMemberModal={setToggleRemoveMemberModal}
        onRemoveClick={onRemoveClick}
      />
    </>
  );
};

export default GroupMembers;
