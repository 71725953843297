import React, { FC, useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import Modal from '../../atoms/Modal';
import { useTranslation } from 'react-i18next';
import MainOptionButton from '../../atoms/buttons/MainOptionButton/MainOptionButton';
import Subheader from '../../atoms/Subheader/Subheader';
import cameraIcon from '../../../icons/camera.svg';
import './styled.css';
import { KeycardType } from '../../../constans/keycardType';

interface QrCodeDetectorProps {
  handleClose: () => void;
  isOpen: boolean;
  onDetect: (code: string) => void;
  keyCardType: KeycardType;
}

const QrCodeDetector: FC<QrCodeDetectorProps> = ({
  isOpen,
  handleClose,
  onDetect,
  keyCardType,
}) => {
  const { t } = useTranslation();
  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState<any>(undefined);

  const setDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(
      (device) => device.kind === 'videoinput'
    );
    setSelectedDeviceId(videoDevices?.[0]?.deviceId);
    setVideoDevices(videoDevices);
  };

  useEffect(() => {
    setDevices();
  }, []);

  const getQueryParams = (url: string) => {
    const params = new URLSearchParams(new URL(url).search);
    const result: any = {};

    params.forEach((value, key) => {
      result[key] = value;
    });

    return result;
  };

  const validateQrCode = (qrCode: string): string => {
    if (keyCardType === KeycardType.AXESS_DESTINATION) {
      const splitQrCode = qrCode?.split('#') || [];
      return splitQrCode?.length > 0 ? splitQrCode[1] : qrCode;
    } else if (keyCardType === KeycardType.SWISS_PASS) {
      const params = getQueryParams(qrCode);
      return params?.S || qrCode;
    } else return qrCode;
  };

  return (
    <Modal isOpen={isOpen}>
      <div className={'qrCodeDetectorContainer'}>
        <div className='exitButton'>
          <MainOptionButton onClick={handleClose}>x</MainOptionButton>
        </div>

        <Subheader icon={cameraIcon} text={t('commons.scanCode')} isBlack />
        {videoDevices.map((device, index) => {
          const isBackCamera =
            device.label.toLowerCase().includes('back') ||
            device.label.toLowerCase().includes('environment');
          return (
            <div
              key={device?.deviceId || index}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: '10px',
                cursor: 'pointer',
              }}
              onClick={() => setSelectedDeviceId(device?.deviceId)}
            >
              <div className={'ratioCircleQrCode'}>
                {selectedDeviceId === device?.deviceId && (
                  <div className={'ratioDotQrCode'} />
                )}
              </div>
              <div>
                {t('commons.' + (isBackCamera ? 'backCamera' : 'frontCamera'))}
              </div>
            </div>
          );
        })}

        <QrReader
          key={selectedDeviceId}
          onResult={(result) => {
            if (!!result) {
              onDetect(validateQrCode(result.getText()));
              handleClose();
            }
          }}
          constraints={{ deviceId: selectedDeviceId }}
          containerStyle={{ maxHeight: '500px' }}
        />
      </div>
    </Modal>
  );
};
export default QrCodeDetector;
