import { gql } from '@apollo/client';

export const SHOP_ENABLED = gql`
  query {
    shopStatus {
      legacyAxessShopEnabled
      legacyAxessShopDisabledMessage
    }
  }
`;
