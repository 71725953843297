import { string, object, boolean } from 'yup';
import { KeycardType } from '../../../constans/keycardType';

const validationSchema = (): object =>
  object().shape(
    Object.assign({
      lastName: string().nullable(),
      firstName: string().required(),
      dayBirth: string().required(),
      monthBirth: string().required(),
      yearBirth: string().required(),
      keyCardType: string(),
      keyCardDisplayNumber: string().when(
        'keyCardType',
        (_type: string, schema: any) =>
          _type !== KeycardType.NO_KEYCARD ? schema.required() : schema
      ),
      zip: string().when('keyCardType', (_type: string, schema: any) =>
        _type === KeycardType.SWISS_PASS ? schema.required() : schema
      ),
      swissPassApproval: boolean().when(
        'keyCardType',
        (_type: string, schema: any) =>
          _type === KeycardType.SWISS_PASS ? schema.oneOf([true]) : schema
      ),
    })
  );
export default validationSchema;
