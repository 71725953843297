import { FC } from 'react';
import FieldWrapper from '../../molecules/FieldWrapper';

interface CheckboxFieldProps {
  type?: string;
  label: string | JSX.Element;
  name: string;
  disabled?: boolean;
  required?: boolean;
  style?: any;
}

const CheckboxField: FC<CheckboxFieldProps> = ({
  label,
  name,
  disabled,
  required = false,
  style,
}) => (
  <FieldWrapper<string> {...{ name, disabled, required }}>
    {(field: any) => (
      <div
        style={{ display: 'flex', alignItems: 'start', paddingBottom: '10px' }}
      >
        <input
          type={'checkbox'}
          {...{ disabled, ...field, value: field.value, checked: field.value }}
          style={{
            cursor: 'pointer',
            padding: 0,
            margin: 0,
            marginTop: '4px',
            accentColor: '#af272f',
            ...style,
          }}
        />
        <div style={{ marginLeft: '8px' }}>{label}</div>
      </div>
    )}
  </FieldWrapper>
);

export default CheckboxField;
